.export-collection-tab {
  .preset-exports {
    .export {
      @extend .box;
      max-width: 600px;

      .description {
        ul {
          list-style: circle;
          padding-left: 30px;
        }
      }
    }
  }
}
