@media screen {
  .DropDown {
    //position: relative; // why? displays behind in some cases...
  }

  .DropDown-selected-item {
    cursor: pointer;
  }

  .DropDown-list {
    position: absolute;
    padding: 0.5em;
    min-width: 200px;
    background: $admin-light-grey-border;

    .DropDown-list-item {
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: $admin-grey;
      }

      &.current {
        background: $admin-grey;
      }
    }
  }

  .DropDown-list-list.DropDown--left {
    left: 0;
  }

  .DropDown-list-list.DropDown--right {
    right: 0;
  }

  .DropDown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .DropDown--open {
    z-index: $zindex-dropdown;
  }

  .DropDown-drop {
    filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.5));
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
