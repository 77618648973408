@media screen {
  .ValueSlider {
    margin: 1em 0;

    .ValueSlider-ticks-wrapper {
      position: relative;
      height: 2em;
      z-index: $zindex-above;
      margin-right: 2em;
    }

    .ValueSlider-tick-item {
      position: absolute;
      top: 0;
      padding-bottom: 0.6em;

      &:after {
        content: '';
        position: absolute;
        top: 1.4em;
        left: 50%;
        width: 1px;
        height: 0.6em;
        background: $admin-light-grey;
      }
    }

    .ValueSlider-inner {
      position: relative;
      height: 2em;
      z-index: $zindex-dropdown;

      .ValueSlider-inner-cursor {
        position: absolute;
        top: 0.3em;
        left: 0;
        width: 1.4em;
        height: 1.4em;
        border-radius: 50%;
        background: $admin-dark;
        cursor: pointer;
      }

      .ValueSlider-inner-bar {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;

        &:after {
          content: '';
          height: 0.7em;
          background: $admin-light-grey;
          border-radius: 0.35em;
          position: absolute;
          top: 0.6em;
          right: 0;
          left: 0;
          z-index: $zindex-below;
        }
      }

      .ValueSlider-inner-result {
        margin-top: 0.5em;
      }
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
