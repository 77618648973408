table.table {
  thead tr th {
    // min-height: 100px;
    min-width: 150px;
    // height: 100px;
    width: 150px;

    &.searching {
      min-width: 300px;
      width: 300px;
    }
  }
}
