.react-datepicker-wrapper {
  & > div {
    .react-datepicker-popper {
      z-index: 9999 !important;
    }
  }

  .with-time-select {
    display: flex;
  }
}
