.FormBase {
  .FormBase-title {
    font-size: 1.51em;
    margin-bottom: 20px;

    .FormBase-title-type {
      color: $admin-dark-grey;
    }
  }

  .FormBase-subtitle {
    font-size: 1.15em;
    //font-weight: 700;

    img {
      width: 100%;
    }
  }

  /*** sidebar ***/

  .FormBase-sidebar {
    background: $admin-light-grey-border;
    padding: 20px;

    .FormBase-sidebar-title {
      text-transform: uppercase;
      font-size: 1.1em;
      font-weight: 700;
      margin-bottom: 1em;
    }

    .FormBase-sidebar-subtitle {
      font-size: 1.05em;
      font-weight: 700;
      margin-bottom: 1em;
    }
  }

  /*** table ***/
  .FormBase-table {
    text-align: left;
    width: 100%;
    max-width: 100%;
    border-spacing: 1px;
    border-collapse: separate;
    margin: 20px 0;

    th {
      background: $admin-light-grey;
      font-weight: 700;
      padding: 0.5em 0.75em;
      cursor: pointer;
    }

    td {
      background: $admin-light-grey-border;
      color: black;
      padding: 0.5em 0.75em;
    }
  }

  .FormBase-leftcol.col {
    overflow: visible; // changed for Megadraft's toolbar
  }
}

.FormBase--primary {
  padding: 20px;
  background: white;

  hr {
    background-color: $admin-grey;
  }
}

/*** SECONDARY FORM with class secondary-form-base-component ***/

.FormBase--secondary {
  border: 0;
  padding-left: 0;
  color: $admin-darkest;
  margin: 20px 0;
}

.ImageField {
  // sert au rendu d'une image (ex readOnly) dans un formulaire
  max-width: 95%;
  max-height: calc(80vh);
}

.Modal {
  .FormElement {
    margin: 1em 0;
    padding: 0 0 0 1em;

    &:first-child {
      margin-top: 0;
    }

    .FormElement {
      border: 0;
      padding: 0;
    }
  }
}

@media screen and (max-width: $break1480) {
  .Modal,
  .DiffusionList {
    .FormElement-input-text-element,
    .FormElement-input-select-element,
    .FormElement-input-date-element,
    .FormElement-input-picker-element {
      .FormElement-inner {
        @include flex-direction(row);
        @include flex-wrap(nowrap);
        @include flex-align-items(center);

        .FormElement-label {
          @include flex-basis(20%);
          padding-right: 20px;
          @include box-sizing(border-box);
        }

        .FormElement-input-text,
        .FormElement-input-select-wrapper,
        .FormElement-date-picker {
          @include flex-basis(80%);
          @include box-sizing(border-box);
        }
      }
    }
  }
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
  .FormBase {
    &-leftcol {
      @include flex-basis(100% !important);
      order: 2;
    }

    &-rightcol {
      @include flex-basis(100% !important);
      order: 1;
      margin-bottom: 20px;
    }
  }

  /*** main form elements large screen layout ***/
  .Modal,
  .DiffusionList {
    .FormElement-input-text-element,
    .FormElement-input-select-element,
    .FormElement-input-date-element,
    .FormElement-input-picker-element {
      .FormElement-inner {
        @include flex-direction(column);
        @include flex-wrap(wrap);
        @include flex-align-items(flex-start);

        .FormElement-label {
          @include flex-basis(auto);
          padding-right: 0;
        }

        .FormElement-input-text,
        .FormElement-input-select-wrapper,
        .FormElement-date-picker {
          @include flex-basis(auto);
          width: 100%;
        }
      }
    }
  }

  &.has-fixed-footer {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
