@media screen {
  .modal-test {
    position: relative;
  }

  .Modal {
    .Modal-wrapper {
      @include display-flex;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zindex-modal;
      background: rgba(0, 0, 0, 0.7);
      overflow-y: scroll;
      margin: 0 auto;
    }

    &.depth-0 .Modal-wrapper {
      z-index: $zindex-modal;
    }

    &.depth-1 .Modal-wrapper {
      z-index: $zindex-modal + 1;
    }

    &.depth-2 .Modal-wrapper {
      z-index: $zindex-modal + 2;
    }

    &.depth-3 .Modal-wrapper {
      z-index: $zindex-modal + 3;
    }

    .Modal-inner {
      max-width: calc(100vw - 80px);
      max-height: calc(100vh - 80px);
      overflow: scroll;
      border-radius: 8px;
      background: white;
      padding: 40px;
      margin: 40px auto;

      // sizes
      &--full {
        max-width: 1440px;
      }

      &--two-third {
        max-width: calc(1440px * 2 / 3);
      }

      &--half {
        max-width: calc(1440px / 2);
      }

      .Modal-inner-wrapper {
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
        position: relative;
      }

      .Modal-content {
        .Modal-content-title {
          font-size: 1.25em;
          margin: 0 3em 1em 0;
        }
      }
    }

    .Modal-close-modal {
      position: absolute;
      cursor: pointer;
    }

    .Modal-dialog {
      margin-top: 20px;

      .Modal-dialog-button {
        display: inline-block;
        padding: 0.25em 0.5em;
        background: $admin-dark;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 700;
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.is-small {
      .Modal-inner-wrapper {
        max-width: 550px;
      }
    }
  }

  .Modal--is-modal {
    .Modal-content {
      .Modal-content-title {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
