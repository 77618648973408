.pagination {
  @media screen and (min-width: 769px) {
    .pagination-list {
      order: 1;
    }

    .pagination-information {
      order: 2;
      margin-right: 5px;
    }

    .pagination-previous {
      order: 3;
    }

    .pagination-next {
      order: 4;
    }
  }
}
