.FormElement {
  .FormElement-input-object-picker-element {
    .FormElement-input-object-picker-list {
      margin: 0.5em 0;
      max-width: 700px;

      .FormElement-input-object-picker-list-item {
        //background: $success-light; // current
        background: $admin-lighter-grey;
        padding: 0.25em 0.25em 0.25em 0.5em;
        margin: 0.25em 0;

        &.future {
          //background: $admin-button-lightest;
        }

        &.current {
          //background: $admin-button-lightest;
          /* does not work
                      &-title {
                          color: $success;
                      }*/
          .schedule-dates {
            color: $success;
          }
        }

        /* does not work
                  &.current &-title {
                      color: $success;
                  }*/

        &.past {
          .schedule-dates {
            color: $admin-grey;
          }

          //background: $admin-light-grey;
        }

        .FormElement-input-object-picker-list-item-title {
          flex-grow: 1;
          overflow: hidden;
          display: block;
          @include overflowing-text;
          max-width: calc(100% - 3.3em);
        }

        .FormElement-input-object-picker-list-item-edit {
          text-indent: -9999em;
          width: 1.4em;
          height: 1.4em;
          margin-left: 0.25em;
          position: relative;
          background: $admin-ui-light;
          cursor: pointer;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../../../images/edit-item-icon.svg') center center
              no-repeat;
            background-size: auto 0.8em;
          }
        }

        .FormElement-input-object-picker-list-item-delete {
          text-indent: -9999em;
          width: 1.4em;
          height: 1.4em;
          margin-left: 0.25em;
          position: relative;
          background: $admin-ui-light;
          cursor: pointer;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url('../../../images/delete-icon.svg') center center
              no-repeat;
            background-size: auto 0.8em;
          }
        }

        .FormElement-input-object-picker-list-item-save {
          width: 1.4em;
          height: 1.4em;
          margin-left: 0.25em;
          position: relative;
          background: $admin-ui-light;
          cursor: pointer;
        }

        .FormElement-input-object-picker-list-item-cancel {
          width: 1.4em;
          height: 1.4em;
          margin-left: 0.25em;
          position: relative;
          background: $admin-ui-light;
          cursor: pointer;
        }
      }
    }

    .FormElement-input-object-picker-button {
      cursor: pointer;
      color: white;
      background: $admin-light;
      padding: 0.35em 0.75em;
      display: inline-block;
      margin: 0.5em 0;
      font-weight: 700;
      align-self: flex-start;

      transition: background-color 0.3s ease-in;

      &:hover {
        background: $admin-light-dark;
      }
    }
  }
}
