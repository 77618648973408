.PaymentTab {
  position: relative;

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .FormBase-button {
      background-color: $alert-light;
    }
  }

  .step {
    margin: 40px 0;
  }

  .step-1 {
    position: relative;

    .user-section {
      position: relative;
      margin-bottom: 30px;

      > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          margin-bottom: 10px;
        }
      }

      .user-info {
        display: flex;
        > div {
          flex-grow: 1;
        }
      }

      form {
        background: white;
      }
    }

    .subscription-section {
      position: relative;

      > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          margin-bottom: 10px;
        }
      }
    }
  }
  .payment-form {
    .StripeElement {
      font-family: inherit;
      font-size: 1em;
      border: 0;
      color: black;
      margin: 0.5em 0;
      padding: 0.25em;
      display: block;
      width: 100%;
      box-sizing: border-box;
      background: transparent;
      border-bottom: 1px solid #cccccc;
      transition: border-color 0.4s ease-in;
      -webkit-appearance: none;
      appearance: none;
    }
  }
}
