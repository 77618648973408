.loading-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(#fff, 0.6);

  &.grey {
    background-color: rgba(#f7f7f7, 0.6);
  }
}
