.AccountingExportTab {
  h2 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
  }

  li {
    margin-top: 0.25em;

    a {
      text-decoration: none;
      color: $admin-dark-grey;
      font-weight: bold;

      &:hover {
        color: $admin-button-active;
      }
    }
  }
}
