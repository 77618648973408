.FormBase--secondary {
  .FormElement-input-checkbox-element {
    outline-color: transparent;

    .FormElement-checkbox-button {
      background: rgba(255, 255, 255, 0);
      height: 1.7em;
      width: 1.7em;
      border: 1px solid $admin-grey;
      border-radius: 0;
      cursor: pointer;
      position: relative;
      display: block;
      margin: 0 auto;

      transition: background-color 0.2s ease-in, border-color 0.2s ease-in;

      .FormElement-checkbox-button-switch {
        position: absolute;
        width: 1em;
        height: 1em;
        top: 0.35em;
        left: 0.35em;
        border-radius: 0;
        background: $admin-dark-grey;

        opacity: 0;

        transition: background-color 0.2s ease-in, opacity 0.2s ease-in;
      }

      &.FormElement-checkbox-button--checked {
        background: rgba(255, 255, 255, 0);

        .FormElement-checkbox-button-switch {
          opacity: 1;
        }
      }
    }
  }
}

.FormElement {
  .FormElement-checkbox-button {
    background: $invalid-light;
    height: 1.4em;
    width: 2.5em;
    border-radius: 0.7em;
    margin-right: 1em;
    cursor: pointer;
    position: relative;

    transition: background-color 0.2s ease-in;

    .FormElement-checkbox-button-switch {
      position: absolute;
      width: 1em;
      height: 1em;
      border-radius: 0.5em;
      top: 0.2em;
      left: 0.2em;
      background: $invalid-dark;

      transition: background-color 0.2s ease-in, left 0.2s ease-in;
    }

    &.FormElement-checkbox-button--checked {
      background: $valid-light;

      .FormElement-checkbox-button-switch {
        background: $valid-dark;
        left: 1.3em;
      }
    }

    &.FormElement-checkbox-button--undefined {
      //background: $admin-grey;

      .FormElement-checkbox-button-switch {
        background: $admin-dark-grey;
      }
    }
  }
}

.FormBase--secondary {
  .FormElement--focus {
    .FormElement--input-checkbox {
      .FormElement-checkbox-button {
        border: 1px solid $admin-dark-grey;
        background: rgba(255, 255, 255, 1);

        &--checked {
          background: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .FormElement--error {
    .FormElement--input-checkbox {
      .FormElement-checkbox-button {
        background: $alert-light;

        &-switch {
          background: $admin-dark;
        }

        &--checked {
          background: $alert-light;
        }
      }
    }
  }
}

.FormElement--error {
  .FormElement-checkbox-button,
  .FormElement-checkbox-button--checked {
    background: $alert-light;
  }

  .FormElement-checkbox-button-switch {
    background: $admin-dark;
  }
}
