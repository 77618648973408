@media screen {
  .AccountingAccountAndTaxInput {
    td {
      //border: 2px solid grey;
      vertical-align: baseline;

      .FormElement {
        margin-bottom: 0;
        padding-right: 1em;
      }
    }

    th {
      background: $admin-ui-light;
    }

    td {
      //background: $admin-lighter-grey;
    }

    .AccountingAccountAndTaxInput-zone {
      padding: 0 1em 0 0;
      margin: 0;
    }
  }
}
