@media screen {
  /*** LAYOUT ***/

  .flex-wrapper {
    @include display-flex;
    @include flex-wrap(wrap);
  }

  .flex-column {
    @include flex-direction(column);
  }

  .row {
    margin: 0 -20px;
  }

  .col {
    @include box-sizing(border-box);
    overflow: hidden;
  }

  .row > .col {
    padding: 0 20px;
  }

  .col-1 {
    @include flex-basis(calc(100% / 12));
  }

  .one-sixth-col,
  .col-2 {
    @include flex-basis(calc(100% / 6));
  }

  .one-quarter-col,
  .col-3 {
    @include flex-basis(25%);
  }

  .one-third-col,
  .col-4 {
    @include flex-basis(calc(100% / 3));
  }

  .col-5 {
    @include flex-basis(calc(100% * 5 / 12));
  }

  .one-half-col,
  .col-6 {
    @include flex-basis(50%);
  }

  .col-7 {
    @include flex-basis(calc(100% * 7 / 12));
  }

  .two-third-col,
  .col-8 {
    @include flex-basis(calc(100% * (2 / 3)));
  }

  .three-quarter-col,
  .col-9 {
    @include flex-basis(75%);
  }

  .five-sixth-col,
  .col-10 {
    @include flex-basis(calc(100% * 5 / 6));
  }

  .col-11 {
    @include flex-basis(calc(100% * 11 / 12));
  }

  .full-col,
  .col-12 {
    @include flex-basis(100%);
  }

  /*** responsive ***/

  /*** container max-width 1240px ***/

  .break1240 {
    .col-1 {
      @include flex-basis(calc(100% / 6));
    }

    .col-11 {
      @include flex-basis(calc(100% * 5 / 6));
    }

    .col-7,
    .col-5 {
      @include flex-basis(50%);
    }
  }

  /*** container max-width 1080px ***/

  .break1080 {
    /** turns 1/4 - 3/4 into 1/3 - 2/3 **/
    .col-9 {
      @include flex-basis(calc(100% * 2 / 3));
    }

    .col-3 {
      @include flex-basis(50%);
    }

    /** turn 1/6 or 1/12 into 1/4 **/
    .col-1,
    .col-2 {
      @include flex-basis(calc(100% / 4));
    }

    .col-10,
    .col-11 {
      @include flex-basis(calc(100% * 3 / 4));
    }
  }

  /** special 1080 rules **/
  .break1080.countCols2 {
    .col-3 {
      @include flex-basis(calc(100% / 3));
    }
  }

  /*** container max-width 840px ***/

  .break840 {
    /** 1/4 - 3/4 **/
    .col-9 {
      @include flex-basis(100%);
    }

    .col-8 {
      @include flex-basis(100%);
    }

    .col-4 {
      @include flex-basis(50%);
    }
  }

  /** 1/4 - 3/4 **/
  .break840.countCols2 {
    .col-4 {
      @include flex-basis(100%);
    }

    .col-3 {
      @include flex-basis(100%);
    }
  }

  .break840.countCols3 {
    .col-4 {
      @include flex-basis(50%);
    }
  }

  .break840.countCols4 {
    .col-3 {
      @include flex-basis(50%);
    }
  }

  /*** container max-width 720px ***/

  .break720 {
    /** 5/12, 1/2 and 7/12 **/
    .col-5,
    .col-6,
    .col-7 {
      @include flex-basis(100%);
    }

    /** turn 1/6 or 1/12 into 1/4 **/
    .col-1,
    .col-2,
    .col-10,
    .col-11 {
      @include flex-basis(100%);
    }
  }

  .break720.countCols3 {
    .col-4 {
      @include flex-basis(100%);
    }
  }

  /*** container max-width 640px ***/

  .break640 {
    /** turn 1/6 or 1/12 into 1/4 **/
    .col-3,
    .col-4 {
      @include flex-basis(100%);
    }
  }

  .break640.countCols4 {
    .col-3 {
      @include flex-basis(100%);
    }
  }
}

@media screen and (max-width: $break1480) {
  .wrapper {
    margin: 0 40px;
  }
}

@media screen and (max-width: $break1240) {
  .wrapper {
    margin: 0 30px;
  }

  .row {
    margin: 0 -15px;
  }

  .row > .col {
    padding: 0 15px;
  }
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
  .wrapper {
    margin: 0 20px;
  }

  .row {
    margin: 0 -10px;
  }

  .row > .col {
    padding: 0 10px;
  }
}

@media screen and (max-width: $break720) {
  .wrapper {
    margin: 0 10px;
  }
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
