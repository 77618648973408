.FormElement {
  .FormElement-input-dropzone {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 1em 2em;
    min-height: 80px;
    max-width: 500px;
    background: $admin-lighter-grey;
    margin: 0.5em 0;
    cursor: pointer;
    font-weight: 700;
  }

  .FormElement-dropped-files-list {
    .FormElement-dropped-file-item {
      @include display-flex;
      margin: 0.5em 0;
      align-items: center;
      align-content: center;
    }

    .FormElement-dropped-file-preview {
      max-width: 100px;
      margin-right: 1em;

      img {
        height: auto;
        width: 100%;
        display: block;
      }

      &--uploading {
        opacity: 0.7;
      }
    }
  }

  .FormElement-input-dropzone-component-inner {
    width: 100%;
  }
}
