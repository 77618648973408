@import './buttons.scss';
@import './table.scss';
@import './switch.scss';
@import './text.scss';
@import './notifications.scss';
@import './cards.scss';
@import './emptyStates.scss';
@import './InformationBubble.scss';
@import './LoadingOverlay.scss';
@import './Centered.scss';
@import './datepicker.scss';
@import 'pagination';
@import 'lists';
@import 'tabs';
@import 'dropdown';
@import 'form';
@import 'placeholders';
@import 'RichTextContent';
@import 'Counter';

.draggable {
  cursor: grab;
}

.SelectSub__menu {
  z-index: 2 !important;
}

.SelectSub__menu-portal {
  z-index: $zindex-select-portal !important;
}

hr.hr-separator {
  &.is-dark {
    background-color: $grey;
  }
}
