@import './Draft.scss';
@import './Form.scss';
@import './Section.scss';
@import './TagSelection.scss';
@import './buttons.scss';
@import './checkboxes.scss';
@import './FormMultiElements.scss';
@import './FormElement.scss';
@import './inputs.scss';
@import './selects.scss';
@import './textareas.scss';
@import './tooltips.scss';
@import './radio.scss';
@import './TagList.scss';
@import './ObjectPicker.scss';
@import './Dropzone.scss';

div.switch-container {
  .switch[type='checkbox'] + label::before,
  .switch[type='checkbox'] + label:before {
    background: $danger;
  }

  .switch[type='checkbox']:checked + label::before,
  .switch[type='checkbox']:checked + label:before {
    background: $success;
  }

  &.is-undefined {
    .switch[type='checkbox'] + label::before,
    .switch[type='checkbox'] + label:before {
      background: $grey-light;
    }
  }
}
