.resource-form-default-editor {
  .tabs {
    margin-bottom: 0;
    margin-top: 20px;

    ul li.is-active a {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .property-group.has-many-groups {
    padding: 10px;
    padding-top: 30px;
    background-color: rgba(0, 0, 0, 0.04);
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }
}

.resource-property.field {
  margin-bottom: 2.5rem;
  padding-left: 20px;
  border-left: 2px solid #bbc2ec;

  &.list {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
  }

  div.Select {
    max-width: 500px;
  }

  &.type-file {
    .box {
      max-width: 300px;
    }

    form {
      max-width: 500px;
    }
  }
}
