@media screen {
  .EntityStatsView-table {
    color: black;

    td {
      background: $admin-table-row;
      padding: 0.5em;
    }

    th {
      background: $admin-table-head;
      padding: 0.5em;
    }

    thead {
      th {
        text-align: center;
      }
    }

    .value {
      text-align: right;
    }

    .trailer.playCount {
      color: #3ec73e;
    }

    .trailer.viewCount {
      color: #008000;
    }

    .full.playCount {
      color: #2826cd;
    }

    .full.viewCount {
      color: #000080;
    }

    .total {
      font-weight: bold;
    }

    // page

    .playCount {
      color: #5f58ff;
    }

    .viewCount {
      color: #0000c4;
    }

    .anonymous {
      background: #e0e0e0;
    }

    .freemium {
      background: #ffa2a2;
    }

    .premium {
      background: #ffb87f;
    }

    .playlist {
      background: #ffb4ef;
    }

    .no_playlist {
      //background: #ffffff;
    }

    .x_trailer {
      background: #b8e29b;
    }

    .x_full {
      background: #addef1;
    }

    .label {
      font-size: 0.85em;
      img {
        height: 1.5em;
      }
    }
  }

  .EntityStatsView-wrapper {
    width: 99%; // to prevent FormBase-rightcol from "falling" do to change with overflow: visible which changes the div width (why ???)
    overflow: hidden;
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
