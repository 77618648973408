.information-bubble {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 6px;
  position: relative;

  button.delete {
    top: 3px;
    right: 4px;
    position: absolute;
    z-index: 1;
    background: none;

    &::before,
    &::after {
      background-color: $grey;
    }
  }

  &.is-info {
    background-color: $info;
    color: $admin-light-dark;
    border: 1px solid lighten($admin-light-dark, 50%);
  }

  &.is-warning {
    background-color: $warning-light;
    color: $admin-light-dark;
  }

  &.is-success {
    background-color: $success-light;
    color: $admin-light-dark;
  }

  &.is-danger {
    background-color: $alert-light;
    color: $admin-light-dark;
  }

  .icon-part {
    margin-right: 20px;
    margin-top: 2px;
  }

  .content-part {
    flex-grow: 1;

    ul {
      padding-left: 15px;
      margin-bottom: 5px;
      margin-top: 5px;

      > li.done {
        text-decoration: line-through;
      }
    }
  }

  &.is-small {
    padding: 5px 10px;
    font-size: 14px;
  }
}
