.VideoServerStatus {
  font-size: 0.9em;
  display: flex;
  min-width: 350px;
  justify-content: space-between;

  .col {
    padding: 0 5px;
  }

  .col-alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #a9bde0;
    margin: 2px;
    border-radius: 3px;

    .alert-level {
      border-radius: 1em;
      text-align: center;
      width: 3em;
      height: 1.5em;
      font-size: 1.5em;
      font-weight: bold;
    }
    .alert-level--0 {
      background: rgba(85, 242, 39, 0.69);
    }

    .alert-level--1 {
      background: #fff400;
    }

    .alert-level--2 {
      background: #ffb100;
    }

    .alert-level--3 {
      background: #ff7400;
    }

    .alert-level--4,
    .alert-level--5,
    .alert-level--6,
    .alert-level--7 {
      background: #ff0900;
    }
  }

  .ProgressBar {
    color: white;
    //height: 18px;
    margin-bottom: 2px;
    background: #001d08;
    padding: 0 5px;
    white-space: nowrap;
    border-radius: 3px;
    overflow: hidden;
    box-sizing: border-box;

    .ProgressBar-bar {
      background: #007500;
    }

    .ProgressBar-bar.warning {
      background: #c97b00;
    }

    .ProgressBar-bar.critical {
      background: #cb0100;
    }
  }

  .alert-offline {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    color: white;
    background: #a60900;

    .big-label {
      font-size: 1.5em;
    }
  }

  .newConnections {
    color: #1f3e00;
  }

  .progress-bar-wrapper {
    background-color: #808fa9;
    padding: 6px;
    border-radius: 3px;
    width: 115px;
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
