@import './_bulma.scss';
@import './_vars.scss';

@import 'fontawesome/brands.scss';
@import 'fontawesome/fontawesome.scss';
@import 'fontawesome/regular.scss';
@import 'fontawesome/solid.scss';

@import './_mixins.scss';
@import './pages/_index.scss';
@import './crud/_index.scss';
@import './old-components/index.scss';
@import './components/index.scss';
@import './_AppLayout.scss';
@import './_App.scss';
@import './_Admin.scss';

@import './old-components/_index.scss';
@import './components/_index.scss';

@import './pages/_index.scss';
@import './crud/_index.scss';
@import './resource-router/_index.scss';
@import './resource/_index.scss';
