.StripeSubscription {
  font-size: 0.85em;
  display: block;
  background: #ff6269;
  border-radius: 5px;
  padding: 2px 5px;

  &.current {
    background: greenyellow;
  }

  &.current.status_trialing {
    background: #73ffee;
  }

  &.test .plan-name::before {
    color: #8e8c8a;
    padding: 0 5px 0 0;
    content: 'Mode test -';
  }
}
