.button {
  &.is-tiny {
    font-size: 0.5em;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
  }

  &.is-small {
    .badge {
      top: 1px;
      right: 3px;
      font-size: 8px;
    }
  }
}
