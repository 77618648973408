@mixin display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flexbox;
  display: -moz-flex;
  display: -o-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

@mixin flex-direction($direction) {
  -moz-flex-direction: $direction;
  -o-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($value) {
  -moz-flex-wrap: $value;
  -o-flex-wrap: $value;
  -webkit-flex-wrap: $value;
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin flex-justify-content($justify) {
  -moz-justify-content: $justify;
  -o-justify-content: $justify;
  -webkit-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
}

@mixin flex-align-content($align) {
  -moz-align-content: $align;
  -o-align-content: $align;
  -webkit-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

@mixin flex-align-items($align) {
  -webkit-flex-align: $align;
  -ms-flex-align: $align;

  -moz-align-items: $align;
  -o-align-items: $align;
  -webkit-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin flex-align-self($align) {
  -moz-align-self: $align;
  -o-align-self: $align;
  -webkit-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin flex-shrink($value) {
  -moz-flex-shrink: $value;
  -o-flex-shrink: $value;
  -webkit-flex-shrink: $value;
  -ms-flex-shrink: $value;
  flex-shrink: $value;
}

@mixin flex-grow($value) {
  -moz-flex-grow: $value;
  -o-flex-grow: $value;
  -webkit-flex-grow: $value;
  -ms-flex-grow: $value;
  flex-grow: $value;
}

@mixin flex-basis($value) {
  -moz-flex-basis: $value;
  -o-flex-basis: $value;
  -webkit-flex-basis: $value;
  -ms-flex-basis: $value;
  flex-basis: $value;
}

@mixin flex-align-self($value) {
  -moz-align-self: $value;
  -o-align-self: $value;
  -webkit-align-self: $value;
  -ms-align-self: $value;
  align-self: $value;
}

@mixin flex-order($value) {
  -moz-order: $value;
  -o-order: $value;
  -webkit-order: $value;
  -ms-order: $value;
  order: $value;
}

@mixin transition($args) {
  -moz-transition: $args;
  -o-transition: $args;
  -webkit-transition: $args;
  -ms-transition: $args;
  transition: $args;
}

@mixin backface-visibility($value) {
  -moz-backface-visibility: $value;
  -o-backface-visibility: $value;
  -webkit-backface-visibility: $value;
  -ms-backface-visibility: $value;
  backface-visibility: $value;
}

@mixin box-sizing($sizing) {
  -moz-box-sizing: $sizing;
  -o-box-sizing: $sizing;
  -webkit-box-sizing: $sizing;
  -ms-box-sizing: $sizing;
  box-sizing: $sizing;
}

@mixin background-size($args) {
  -moz-background-size: $args;
  -o-background-size: $args;
  -webkit-background-size: $args;
  -ms-background-size: $args;
  background-size: $args;
}

@mixin transform($args) {
  -moz-transform: $args;
  -o-transform: $args;
  -webkit-transform: $args;
  -ms-transform: $args;
  transform: $args;
}

@mixin transform-origin($args) {
  -moz-transform-origin: $args;
  -o-transform-origin: $args;
  -webkit-transform-origin: $args;
  -ms-transform-origin: $args;
  transform-origin: $args;
}

@mixin filter($args) {
  -moz-filter: $args;
  -o-filter: $args;
  -webkit-filter: $args;
  -ms-filter: $args;
  filter: $args;
}

@mixin overflowing-text {
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin object-fit($args) {
  -moz-object-fit: $args;
  -o-object-fit: $args;
  -ms-object-fit: $args;
  -webkit-object-fit: $args;
  object-fit: $args;
}

@mixin column($number, $gap) {
  -moz-column-count: $number;
  -o-column-count: $number;
  -webkit-column-count: $number;
  -mscolumn-count: $number;
  column-count: $number;
  -moz-column-gap: $gap;
  -o-column-gap: $gap;
  -webkit-column-gap: $gap;
  -ms-column-gap: $gap;
  column-gap: $gap;
}

@mixin column-break($value) {
  -webkit-column-break-inside: $value;
  -moz-column-break-inside: $value;
  column-break-inside: $value;
  page-break-inside: $value;
  break-inside: $value;
}
