.FormElement-label {
  @include display-flex;
  color: $admin-darker-grey;
  align-items: center;
}

.FormElement-error-message {
  color: red;
  font-size: 0.85em;
  margin-top: 0.5em;
}

.FormElement {
  margin: 2em 0;
  padding: 0 0 0 1em;
  border-left: 0.15em solid $admin-ui-main;
  transition: border-color 0.4s;
  outline-color: transparent;

  .FormElement-count-char {
    font-size: 0.85em;
    font-weight: 700;
  }

  .FormElement-success-message {
    color: green;
    font-size: 0.85em;
    margin-top: 0.5em;
  }

  &:first-child {
    margin-top: 0;
  }

  .FormElement {
    border: 0;
    padding: 0;
  }

  .FormElement--focus {
    border-color: $admin-ui-dark;

    .react-tagsinput {
      border-bottom: 1px solid $admin-light-grey-border;
    }
  }

  .FormElement--error {
    border-color: $alert-light;
  }

  .FormElement--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
