.default-placeholder {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  border: 1px solid $grey-lighter;
  background: $grey-lightest;
}
