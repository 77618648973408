.FormElement-tags-list {
  @include display-flex;
  margin: 0.5em 0 0 0;
}

.FormElement-tags-list-item {
  display: inline-block;
  padding: 0.25em 0.5em;
  cursor: pointer;
  margin: 0 0.5em 0.5em 0;
  background: $admin-button-lightest;
  font-weight: 700;

  transition: background-color 0.2s ease-in, color 0.2s ease-in;

  &:hover {
    background: $admin-button-light;
  }

  &.FormElement-tags-list-item--active {
    background: $admin-ui-main;
  }
}
