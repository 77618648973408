@import 'LayoutEditor';

@mixin property-box {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 15px;
  margin-bottom: 0.75rem;
  border-radius: 10px;
}

.field.resource-property {
  .card-header-title {
    white-space: nowrap;
    overflow: hidden;
    max-width: 315px;
  }

  &.type-string,
  &.type-richtext {
    .string-length-counter {
      position: absolute;
      top: -10px;
      right: -15px;
      z-index: 1;

      .counter {
        box-shadow: rgba(50, 50, 93, 0.4) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.5) 0px 1px 3px -1px;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .object-property-editor {
    @include property-box;
  }

  .file-property-editor {
    @include property-box;
  }

  .layout-property-editor {
    @include property-box;

    .component-picker-section {
      .components {
        .component {
          margin-right: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .color-property-editor {
    @include property-box;

    .color-value {
      height: 20px;
      width: 40px;
      border: 1px solid black;
      margin-right: 5px;
      display: inline-block;
    }

    .InputColorField-background-color {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
    }
  }

  .object-list-property-editor {
    @include property-box;

    .object-property-editor {
      border: 1px solid darken($darkened-app-content-background-color, 10%);
    }
  }

  .object-list-editor {
    margin-bottom: 30px;
    padding-left: 20px;
    border-left: 1px solid $grey-lighter;
    padding-bottom: 20px;

    .object-list-editor-item {
      .item-label-wrapper {
        max-width: calc(100% - 120px);

        .item-label {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .actions-wrapper {
      border-top: 1px solid $grey-lighter;
      margin-left: 50px;
      margin-right: 50px;
      padding-top: 15px;
      margin-top: 30px;

      .add-button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .resource-list-editor {
    .items {
      flex-wrap: wrap;
      .card {
        margin-bottom: 5px;
      }
    }

    .action {
      margin-top: 5px;
    }
  }

  .object-ref-editor {
    flex-wrap: wrap;

    .card.object-ref-card {
      margin-bottom: 5px;

      .card-header {
        .card-header-title {
          overflow: hidden;
          max-width: 315px;
          white-space: nowrap;
        }
      }
    }
  }
}
