.centered {
  display: flex;

  &.horizontally {
    justify-content: center;
  }

  &.vertically {
    align-items: center;
  }

  &.fully-expanded {
    width: 100%;
    height: 100%;
  }
}
