// https://github.com/moroshko/react-autosuggest#theme-optional
.react-autosuggest__suggestions-container {
  ul {
    list-style-type: none;
    padding: 10px;
    margin: 0;
    //border: 1px solid black;
    filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.5));
    background: white;
  }

  .suggestion {
    &.highlighted {
      background: $admin-button-active-dark;
      color: white;
    }

    .search-term {
    }
  }

  .react-tagsinput-tag {
    white-space: pre-wrap; // to make multiple spaces visible
  }
}

/*** tags selection ***/
.FormElement-tags-selection {
  .react-tagsinput {
    font-family: inherit;
    font-size: 1em;
    color: black;
    margin: 0.5em 0;
    padding: 0.25em;
    display: block;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    border-bottom: 1px solid $admin-light-grey;
    transition: border-color 0.4s ease-in;

    &-tag {
      display: inline-block;
      margin: 0.1em 0.25em;
      background: $admin-button-lightest;
      padding: 0.25em 0.5em;
      font-weight: 700;

      transition: background 0.2s ease-in;

      white-space: pre-wrap; // to make multiple spaces visible

      .Renderer-Default /*.Tag*/ {
        display: inline-block;
      }

      &.existing {
        background: $admin-button-light;
      }

      &.to-create {
        background: pink;
      }
    }

    &-tag:nth-child(1) {
      margin-left: 0;
    }

    &-tag:hover {
      background: $admin-button-light;
    }

    &-input {
      width: 300px; // for placeholder
      display: inline-block;
      background: transparent;
      border: 0;
      font-size: 1em;
      color: inherit;
      font-family: inherit;
      outline-color: transparent;
      margin-left: 0.75em;
    }

    &-remove {
      cursor: pointer;
      margin-left: 0.25em;
    }
  }
}
