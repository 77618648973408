.VideoEncodingPage-row {
  border: 1px solid black;
  margin: 2px;
  padding: 5px;

  .encoding {
    background: #fff800;
  }

  .pending {
    background: #81ccc8;
  }

  .done {
    background: #7cff00;
  }

  .error {
    background: #ff5656;
  }
}
