// breakpoints
$break1480: 1480px;
$break1240: 1240px;
$break1080: 1080px;
$break840: 840px;
$break720: 720px;
$break640: 640px;
$break480: 480px;
$sidebarCollapseBreak: $break840;

// colors
$alert: red;
$alert-light: #cd9e9e;
$warning: orange;
$success: green;
$success-light: #adf28f;
$warning-light: #ffdb69;
$info: #ffef97;

$valid-light: #94cc7a;
$valid-dark: #0d800d;
$invalid-light: #e68a8d;
$invalid-dark: #9c2f34;

// darker colors
$admin-darkest: #0c0c0f;
$admin-darker: #14151b;
$admin-dark: #2b2d39;
$admin-light-dark: #4a4c59;
$admin-light: #646880;
$admin-lighter: #9a9fb7;

// UI & forms colors
$admin-ui-darkest: #858aa3;
$admin-ui-darker: #9a9fb7;
$admin-ui-dark: #5964a4;
$admin-ui-middle: #a7add3;
$admin-ui-main: #bbc2ec;
$admin-ui-light: #bbc2df;
$admin-ui-lighter: #ced3e8;
$admin-ui-lightest: #d9dff4;

// buttons colors
$admin-button-lightest: #dde1f2;
$admin-button-light: #ced5ef;
$admin-button-active: #e2cc63;
$admin-button-active-dark: $info;

// admin tables
$admin-table-head: #dadeef;
$admin-table-row: #e9eaf4;

// admin layout builder
$admin-layout-col: #5964a4;
$admin-layout-col-hover: #475087;
$admin-layout-col-no-drop: #a45959;
$admin-layout-edit-col: #eaeaea;
$admin-layout-add-col: #afb6ed;
$admin-layout-add-col-hover: #9ba3e6;
$admin-layout-add-col-border: #1b1c24;

// greys
$admin-lightest-grey: #f1f1f1;
$admin-light-grey-border: #e5e5e5;
$admin-lighter-grey: #dddddd;
$admin-light-grey: #cccccc;
$admin-grey: #aaaaaa;
$admin-dark-grey: #777777;
$admin-darker-grey: #555555;
$admin-darkest-grey: #111111;

$facebook-color: #3b5ca8;
$google-color: #dc4e38;
$twitter-color: #5ebef2;
$youtube-color: #b90000;

// z indexes
$zindex-below-all: -2;
$zindex-below: -1;
$zindex-neutral: 0;
// A clarifier : certains eléments ont le z-index "above" ou "dropdown" alors que ce ne sont pas des dropdown
// (du coup ils passent devant)
// Ex:
// LayoutTemplateColContent-item : dropdown
// edit-buttons-fixed : dropdown
// etc.
// Corrigé en rajoutant des z-index peut-être inutiles - à checker // TODO Martin
$zindex-above: 1;
$zindex-above-above: 2;
$zindex-layout-1-TODO: 2;
$zindex-layout-2-TODO: 3;
$zindex-edit-buttons-TODO: 3;
$zindex-dropdown: 4;
$zindex-overlay: 5;
$zindex-modal: 31;
$zindex-above-all: 7;
$zindex-select-portal: 100;

$text: $admin-dark;
$sidebar-size: 250px;
$sidebar-retracted-size: 25px;
$app-content-padding: 30px;
$app-content-background-color: lighten($grey-lightest, 4%);
$darkened-app-content-background-color: darken(
  $app-content-background-color,
  3%
);
