// TODO Martin discuter de ça

$onfray-gray: #646362;
$onfray-blue: #3366cc;
$onfray-dark-blue: #13245d; /* ex red : #990000 */
$onfray-red: #e52330;
$onfray-dark-red: #960911;
$onfray-violet: #5d2784;
$onfray-green: #66bb73; /* darker green : #4f945e */
$onfray-orange: #ed802f;
$onfray-pink: #ec7e67;

.content-type-value {
  display: inline-block;
  color: white;
  background-color: $onfray-gray;
  border-radius: 0.2em;
  padding: 0.125em 0.25em;
  margin: 0.2em 0 0.2em 0;

  &.weekly-video-posts {
    background-color: $onfray-blue;
  }

  &.conference {
    background-color: $onfray-orange;
  }

  &.guest {
    background-color: $onfray-pink;
  }

  &.upc {
    background-color: $onfray-dark-red;
  }

  &.archive {
    background-color: $onfray-violet;
  }

  &.just-answer {
    background-color: $onfray-green;
  }
}
