/* App container with its subparts: .app-navigation and .app-content */
$sidebar-retracted-left-offset: calc(
  #{$sidebar-size} - #{$sidebar-retracted-size}
);

.app-container {
  position: relative;
  @include display-flex;
  width: 100%;

  .app-navigation {
    padding: 10px;
    background: white;
    max-width: $sidebar-size;
    min-width: $sidebar-size;
    color: white;
    box-sizing: border-box;
    position: relative;
    z-index: $zindex-overlay;
    overflow: scroll;
    height: 100%;
    box-shadow: 2px 0px 4px 0 rgba(0, 0, 0, 0.1);

    .menu .menu-list li a {
      font-size: 16px;
      .tag {
        font-size: 12px;
      }
    }
  }

  .app-content {
    overflow: scroll;
    padding: $app-content-padding;
    box-sizing: border-box;
    background-color: $app-content-background-color;
    height: 100%;
    flex-grow: 1;
    position: relative;
  }

  .app-navigation + .app-content {
    width: calc(100% - #{$sidebar-size});
  }
}

@media screen and (max-width: $sidebarCollapseBreak) {
  .app {
    overflow: hidden;
  }

  .app-container {
    .app-navigation {
      position: absolute;
      top: 0;
      bottom: 0;
      width: $sidebar-size;
      z-index: $zindex-dropdown;
      transform: translate3D(
        calc(#{$sidebar-retracted-size} - #{$sidebar-size}),
        0,
        0
      );
      transition: transform 0.25s ease-out;

      &:hover {
        transform: translate3D(0, 0, 0);
        transition: transform 0.4s ease-in;
      }
    }

    .app-content {
      padding: 30px;
    }

    .app-navigation + .app-content {
      position: relative;
      z-index: $zindex-above;
      margin-left: $sidebar-retracted-size;
      padding: 20px;
    }
  }

  .sidebar-toggled {
    .app-navigation {
      transform: translate3D(0, 0, 0);
    }
  }

  .MainMenu-list {
    padding-top: calc(1.4em + 20px);
  }
}

@media screen and (max-width: $break840) {
  .app-container {
    .app-container {
      padding: 20px 0;
    }
  }
}

@media screen and (max-width: $break720) {
  .app-container {
    .app-container {
      padding: 10px 0;
    }
  }
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}

/* NAVBAR */
nav.navbar {
  .has-dropdown {
    .navbar-dropdown {
      display: none;
    }
    &.is-active {
      .navbar-dropdown {
        display: block;

        &.is-boxed {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0);
        }
      }
    }
  }

  .navbar-item#instance-select {
    a.navbar-link {
      color: var(--environment-primary-color, blue);
    }

    a.navbar-link,
    a.navbar-item {
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    a.navbar-item:hover {
      background-color: var(--environment-primary-color, blue);
      color: white;
    }
  }

  #environment-tag {
    position: absolute;
    top: 45px;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: $navbar-box-shadow-size $navbar-box-shadow-color;
  }

  .navbar-item.is-active:hover {
    color: #4a4a4a;
  }

  .navbar-brand + #instance-select {
    width: calc(#{$sidebar-size} - 52px + 1px);
    border-right: 1px solid #dadada;
  }

  a.navbar-item.is-active:focus {
    background-color: var(--environment-primary-color, blue);
    color: white;
  }

  @media screen and (max-width: 1024px) {
    display: flex;

    .navbar-menu {
      position: absolute;
      top: 50px;
      right: 0px;
    }

    // Ugly copy paste of bulma style to make it work on tablet and mobile, maybe there is a better way ?
    .navbar-dropdown {
      background-color: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: 2px solid #dbdbdb;
      box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
      display: none;
      font-size: 0.875rem;
      left: 0;
      min-width: 100%;
      position: absolute;
      top: 100%;
      z-index: 20;

      &.is-boxed {
        border-radius: 6px;
        border-top: none;
        box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1),
          0 0 0 1px rgba(10, 10, 10, 0.1);
        display: block;
        opacity: 0;
        pointer-events: none;
        top: calc(100% + (-4px));
        transform: translateY(-5px);
        transition-duration: 86ms;
        transition-property: opacity, transform;
      }
    }
  }
}

.notification-center {
  position: fixed;
  top: 65px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  width: calc(100% - #{$sidebar-size} - #{2 * $app-content-padding});
}
