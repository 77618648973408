.Diff {
  background-color: initial;
  .Diff__text {
    font-size: 0.75em;
    line-height: 1em;
  }

  .Diff__text--removed {
    opacity: 0;
  }
}

.log-item {
  margin: 0.5em 0 0 0.6em;
}

.log-item span::before {
  content: '✓ ';
  color: green;
  font-weight: bold;
}

.log-item span.error::before {
  content: '✗ ';
  color: red;
  font-weight: bold;
}

.FormBase-button.staging {
  // devserver
  background: #1a6eff;
  color: white;
}

.FormBase-button.prod {
  background: #930012;
  color: white;
}

.FormBase-button.dev {
  // local
  background: #008d18;
  color: white;
}
