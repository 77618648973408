.AbowebSynchroTab {
  li {
    margin-top: 1em;
  }

  .message {
    padding-left: 2em;

    &.action {
      color: #008000;
    }
  }

  .email {
    color: #0303cd;
    font-weight: bold;
    font-family: monospace;
  }

  .aboweb-id {
    color: #1f4df1;
    font-weight: bold;
  }
}
