@import '../../vars.scss';

@media screen {
  .AccountingConfigInput {
    .AccountingConfigInput-header {
      background: $admin-ui-light;
      padding: 3px;
      cursor: pointer;

      .label {
        font-weight: bold;
        margin-right: 0.5em;
      }
    }

    .AccountingConfigInput-item {
      display: flex;
      .AccountingConfigInput-item-content {
        flex-grow: 1;

        > .FormElement:first-child {
          // ugly, but className is not customizable here
          margin-bottom: 0em;
        }
      }
    }
  }
}
