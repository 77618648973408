.FormBase--secondary {
  .FormElement-input-select-wrapper {
    .FormElement-input-select {
      outline-color: transparent;
      line-height: 1.2;
      border: 1px solid $admin-grey;
      color: black;
      background: rgba(255, 255, 255, 0);

      transition: background 0.2s ease-in, border-color 0.2s ease-in;

      option {
        padding: 0.1em 0.25em;
      }

      &:focus {
        background: rgba(255, 255, 255, 1);
        border: 1px solid $admin-dark-grey;
      }
    }
  }
}

.FormElement {
  .FormElement-input-select-wrapper {
    position: relative;

    .FormElement-input-select {
      /* REACT SELECT */

      margin: 0.5em 0.5em 0.5em 0;

      // .FormElement-input-select-inner {
      .FormElement-input-select-inner__control {
        border-radius: 0;
        border: 0;
        outline-color: transparent;
        background: transparent;
        border-bottom: 1px solid $admin-light-grey;
        color: black;
        box-shadow: none;

        .FormElement-input-select-inner__control--is-focused,
        &:hover {
          border: 0;
          border-color: transparent;
          border-bottom: 1px solid $admin-light-grey;
        }
      }

      .FormElement-input-select-inner__value-container {
        padding: 0.25em;
      }

      .FormElement-input-select-inner__indicator-separator {
        display: none;
      }

      .FormElement-input-select-inner__dropdown-indicator,
      .FormElement-input-select-inner__clear-indicator {
        padding: 0.125em;

        svg {
          fill: black;
        }
      }

      .FormElement-input-select-inner__menu {
        margin: 0;
        z-index: $zindex-dropdown;

        .FormElement-input-select-inner__menu-list {
          padding: 0;
        }
      }

      .FormElement-input-select-inner__option {
        background: white;

        &:hover {
          color: $admin-darkest;
          background: $admin-lightest-grey;
        }
      }

      .FormElement-input-select-inner__option--is-selected {
        color: $admin-darkest;
        background: $admin-lighter-grey;

        &:hover {
          color: $admin-darkest;
          background: $admin-lighter-grey;
        }
      }

      .FormElement-input-select--error {
        border-bottom: 1px solid $alert;
      }

      .empty-option-label {
        opacity: 0.5;
      }
    }
  }
}
