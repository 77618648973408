.resource-table {
  .resource-table-filter {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $grey-light;
    .title {
      margin-bottom: 7px;
    }
  }
}
