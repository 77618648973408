.layout-property-editor {
  .toolbox-wrapper {
    background: $grey-lighter;
    border-radius: 3px;
  }

  .layout-editor-content {
    padding: 5px;

    .layout-row-separator {
      height: 1px;
      background-color: black;
      margin-top: 20px;
    }

    .layout-editor-row {
      padding: 5px;
      background-color: #0000ff1c;
      border: 1px solid #0000ffb8;
      border-radius: 3px;

      &:not(:first-child) {
        padding-top: 20px;
        margin-top: 20px;
      }

      .column-wrapper {
        padding: 5px;
        .layout-column {
          padding: 10px;
          background-color: #00ff581c;
          border: 1px solid #00ff5984;
          border-radius: 3px;
          margin-bottom: 10px;
          min-height: 150px;

          .column-content {
            &.selected {
              border: 2px solid blue;
            }
          }
        }
      }
    }
  }
}

.layout-picker {
  .layout {
    margin-bottom: 10px;
    min-height: 70px;
    padding: 10px;

    .layout-column {
      justify-content: space-between;
      font-size: 10px;
      padding: 5px;
      background: $grey-lightest;
      text-align: center;
      margin: 0 2px;
    }
  }
}
