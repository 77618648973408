.rc-tooltip {
  background: $admin-dark;
  line-height: 1.4;
  font-size: 0.8em;
  padding: 0;

  .rc-tooltip-inner {
    padding: 0.5em 1em;
    color: white;
    background: none;
    border: 0;
    border-radius: 0;
    min-height: 0;
    background: $admin-dark;

    a {
      color: inherit;
    }
  }

  .rc-tooltip-placement-right {
    .rc-tooltip-arrow {
      border-right-color: $admin-dark !important;
    }
  }

  .rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      border-left-color: $admin-dark !important;
    }
  }

  .rc-tooltip-placement-top,
  .rc-tooltip-placement-topRight,
  .rc-tooltip-placement-topLeft {
    .rc-tooltip-arrow {
      border-top-color: $admin-dark !important;
    }
  }

  .rc-tooltip-placement-bottom,
  .rc-tooltip-placement-bottomRight,
  .rc-tooltip-placement-bottomLeft {
    .rc-tooltip-arrow {
      border-bottom-color: $admin-dark !important;
    }
  }
}

.FormElement-tooltip {
  .FormElement-tooltip-trigger {
    display: inline-block;
    margin-left: 0.75em;
    height: 1.2em;
    width: 1.2em;
    font-size: 0.9em;
    border-radius: 50%;
    color: white;
    background: #c7d6e4;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
    text-decoration: none;
  }

  /* TODO remove */
  .__react_component_tooltip.type-dark {
    background-color: $admin-dark !important;
  }

  .__react_component_tooltip.type-dark.place-right:after {
    border-right-color: $admin-dark !important;
  }

  .FormElement-text {
    white-space: pre-line;
  }
}
