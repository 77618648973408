@mixin additionalfield($color) {
  border: 1px solid;
  border-color: $color;
  display: inline-block;
  padding: 0;
  margin-right: 0.3em;

  .field-name {
    background: $color;
    color: white;
    padding: 0 0.2em 0 0.2em;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    bottom: 0;
  }
  .field-value {
    padding: 0 0.2em 0 0.2em;
    display: inline-block;
  }
}

@mixin smallChars($content, $color, $size: 0.75em) {
  font-weight: normal;
  content: $content;
  letter-spacing: -0.2em;
  padding-right: 0.2em;
  font-size: $size;
  color: white;
  background: $color;
  width: 20px;
  height: 18px;
  text-align: center;
  display: inline-block;
}

@mixin propType($content, $color, $size: 0.75em) {
  color: $color;
  &:before {
    @include smallChars($content, $color, $size);
  }
}

.code {
  font-family: Consolas, Courier New, monospace;
  font-size: 0.8em;
}

.property {
  .type {
    font-family: 'Courier New';
    font-weight: bold;
    display: inline-block;
  }

  .type.int {
    @include propType('123', blue);
  }

  .type.float {
    @include propType('4.5', #0081ff);
  }

  .type.string {
    @include propType('abc', deeppink);
  }

  .type.richtext {
    @include propType('Rte', #fc7b8d);
  }

  .type.bool {
    @include propType('☑', green, 1em);
  }

  .type.timestamp {
    @include propType('🕑', #a78e43);
  }

  .type.file {
    @include propType('🗋', #ff4816, 1em);
  }

  .type.enum {
    @include propType('☰', #91000c, 0.9em);
  }

  .type.object {
    @include propType('{ }', black);
  }

  .type.list {
    @include propType('[ ]', #91008c);
  }

  .type.object_id {
    @include propType('#', #66d776);
  }

  .type.object_ref {
    @include propType('*', #5b0eab);
  }

  .description {
    font-style: italic;
    color: grey;
  }

  .required {
    color: red;
  }

  .name {
    font-weight: bold;
    display: inline-block;
  }

  .field {
    @include additionalfield(grey);
  }

  .field.min {
    @include additionalfield(#0069ff);
  }

  .field.max {
    @include additionalfield(blue);
  }

  .field.values {
    @include additionalfield(#7a00c0);
  }

  .field.unique {
    @include additionalfield(#dfa700);
  }

  .field.itemSchema {
    display: block;
  }
}

.schema-cls {
  background: $admin-ui-lightest;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;

  .property {
    margin-bottom: 0.33em;

    .type {
      min-width: 8em;

      &:before {
        margin-right: 0.5em;
      }
    }

    .name {
      width: 150px;
    }
  }
}
