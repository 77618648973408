body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media screen {
  body {
    margin: 0;
    font-size: 16px;
    line-height: 1.4;
    font-family: 'PT Sans', Arial, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1.2;
  }

  header {
    text-align: center;
  }

  .col-info {
    background: lightblue;
    width: 100%;
    margin-bottom: 2px;
  }

  .level2 .col-info {
    background: pink;
  }

  .level3 .col-info {
    background: lightyellow;
  }

  .wrapper {
    max-width: 1440px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }

  /*** TEMP ***/

  .col {
    /*text-align: center;*/
  }

  .break480 {
    background: #010;
  }

  .break640 {
    background: #030;
  }

  .break720 {
    background: #050;
  }

  .break840 {
    background: #070;
  }

  .break1080 {
    background: #090;
  }

  .break1240 {
    background: #00ff00;
  }
}

@media screen and (max-width: 1480px) {
  .wrapper {
    margin: 0 40px;
  }
}

@media screen and (max-width: 1240px) {
  .wrapper {
    margin: 0 30px;
  }
}

@media screen and (max-width: 1080px) {
}

@media screen and (max-width: 840px) {
  .wrapper {
    margin: 0 20px;
  }
}

@media screen and (max-width: 720px) {
  .wrapper {
    margin: 0 10px;
  }
}

@media screen and (max-width: 640px) {
}

@media screen and (max-width: 480px) {
}

#back-button {
  background-color: rgba($env-color-rgb, 0.1);
  text-decoration: none;
}
