.FormElement {
  .FormElement-radio-buttons {
    @include display-flex;

    .FormElement-radio-button {
      margin: 0.5em 1.5em 0.5em 0;
      padding-left: 2em;
      position: relative;
      cursor: pointer;

      transition: color 0.2s ease-in;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.4em;
        height: 1.4em;
        border-radius: 0.7em;
        background: $admin-grey;
        display: block;

        transition: background-color 0.2s ease-in;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0.2em;
        left: 0.2em;
        width: 1em;
        height: 1em;
        border-radius: 0.5em;
        background: $admin-dark;
        display: block;

        transform: scale(0);
        transition: transform 0.2s ease-in;
      }

      &.FormElement-radio-button--selected {
        color: $admin-light;

        &:before {
          background: $admin-lighter;
        }

        &:after {
          transform: scale(1);
        }
      }
    }
  }
}
