.AbowebTab {
  h2 {
    margin-bottom: 0.5em;
  }

  h3 {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  th {
    text-align: left;
    color: $admin-dark-grey;
    padding-right: 2em;
  }
}
