.component-finder {
  .unused-components ul,
  .unused-modes ul,
  .used-but-not-defined-components ul {
    padding-left: 30px;
    list-style: disc;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  .finder {
    .placeholder {
      padding: 50px;
      background: rgba(0, 0, 0, 0.05);
      text-align: center;
      font-size: 24px;
    }
  }
}
