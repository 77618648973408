@media screen {
  .FormSection {
    position: relative;
    margin: 1em 0;

    .FormSection-header {
      @include display-flex;
      @include flex-align-items(center);
      @include flex-align-content(center);
      @include flex-justify-content(space-between);
      overflow: hidden;

      .FormSection-header-inner {
        font-size: 1.05em;
        font-weight: 700;
        cursor: pointer;
        width: calc(100% - 70px);
      }

      .FormSection-header-content {
        @include display-flex;
      }

      .FormSection-header-title {
        display: block;
        margin: 0 0.25em;
        @include flex-shrink(1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        max-width: calc(100% - 200px);
      }

      .FormBase-button {
        font-size: 0.95em;
        margin: 0 0.25em;
        padding: 0.125em 0.5em;
      }
    }

    .FormSection-toggle-visibilty {
      margin-left: auto;
      flex-shrink: 0;
      cursor: pointer;
      font-weight: 700;
      padding: 0.25em 0.5em;
      background: $admin-lighter-grey;

      transition: background-color 0.2s ease-in;

      &:hover {
        background: $admin-light-grey;
      }
    }

    .FormSection-inner {
      //overflow: hidden;
      max-height: 500em;
      box-sizing: border-box;
      transition: max-height 0.4s ease-in, padding 0.4s ease-in,
        opacity 0.4s ease-in;

      padding: 20px 0 20px 0.5em;
      margin-left: 2px;
      border-left: 2px solid $admin-ui-middle;
    }

    .FormElement:first-child {
      margin-top: 1em;
    }

    .FormElement:last-child {
      margin-bottom: 0;
    }
  }

  .FormSection--hidden {
    .FormSection-inner {
      max-height: 0;
      padding: 0 20px 0 0;
      opacity: 0;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
  .FormSection {
    .FormSection-header {
      .FormSection-header-content {
        @include flex-direction(column);
      }

      .FormSection-header-inner {
        max-width: calc(100% - 70px);
      }

      .FormSection-header-title {
        max-width: 100%;
        margin-bottom: 0.25em;
      }
    }
  }
}

@media screen and (max-width: $break480) {
}
