$helper-lightbulb-top-offset: 1px;
$helper-lightbulb-left-offset: 2px;

.notification {
  &.is-helper {
    .light-bulb {
      line-height: 1;
      margin: 10px;
    }

    a:not(.button) {
      color: $primary;
      &:not(:hover) {
        text-decoration: none;
      }
    }
  }
}
