// TODO Martin discuter de ça

.published {
  color: $success;
}

.prepub {
  color: $warning;
}

.archived {
  color: $alert;
}
