@media screen {
  .InputColorField {
    .color-value {
      height: 20px;
      width: 40px;
      border: 1px solid black;
      margin-right: 5px;
      cursor: pointer;
      // Center "N/A" text:
      color: red;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .InputColorField-modal-col-wrapper {
      padding-bottom: 20px;
    }

    .InputColorField-background-color {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
    }

    .InputColorField-text-color {
      margin-top: 20px;
      text-align: center;
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
  .InputColorField {
  }
}

@media screen and (max-width: $break1080) {
  .InputColorField {
    .InputColorField-modal-col-wrapper > .one-half-col {
      margin: 20px 0;
      @include flex-basis(100%);
    }
  }
}

@media screen and (max-width: $break840) {
  .InputColorField {
  }
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
  .InputColorField-list {
    .InputColorField-list-item {
      @include flex-basis(50%);
    }
  }
}

@media screen {
  .ColorPicker-Wrapper {
    .photoshop-picker {
      width: 100% !important;
      .flexbox-fix {
        & > div:first-child {
          width: 60% !important;
        }

        & > div:nth-child(2) {
          width: 10% !important;
        }

        & > div:last-child {
          width: 50% !important;

          .flexbox-fix {
            & > div:last-child {
              & > div:last-child {
                width: 100% !important;
                & > div {
                  & > input {
                    text-align: right;
                    margin-left: 0 !important;
                  }

                  & > span {
                    width: 0 !important;
                  }
                }

                & > div:nth-child(9) {
                  & > input {
                    margin-right: 10px !important;
                    width: 40% !important;
                  }
                }

                & > div:last-child {
                  top: 4% !important;
                  right: 20% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
