@import '../vars.scss';

.tabsHeader {
  background: whitesmoke;

  .tabButton {
    display: inline-block;
    margin: 0.2em 0.5em 0 0.2em;
    padding: 0.2em 0.5em;
    font-size: 1.1em;
    cursor: pointer;
    color: $admin-dark;

    &.active {
      color: black;
      background: white;
      border-radius: 7px 7px 0 0;
    }

    &.disabled {
      color: $admin-dark-grey;
      cursor: not-allowed;
    }
  }
}

.tabs a:hover {
  border-bottom-color: #035d0d;
  color: #035d0d;
}

.tabs a {
  color: #b3b3b3;
}

.tabs li.is-active a {
  border-bottom-color: #035d0d;
  color: #035d0d;
}

.tabContent {
  background: white;
  padding: 10px;
}
