// https://www.draft-js-plugins.com/plugin/image
//

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.options {
  margin-bottom: 20px;
}

.DraftEditor-editorContainer {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;

  figure {
    margin: 0;
  }
}

.FormElement-input-rte-toolbar-select-header-three-option,
.DraftEditor-editorContainer h1 {
  font-size: 30px;
  font-weight: bold;
}

.FormElement-input-rte-toolbar-select-header-three-option,
.DraftEditor-editorContainer h2 {
  font-size: 24px;
  font-weight: bold;
}

.FormElement-input-rte-toolbar-select-header-three-option,
.DraftEditor-editorContainer h3 {
  font-size: 20px;
  font-weight: bold;
}

.FormElement-input-rte-toolbar-select-header-four-option,
.DraftEditor-editorContainer h4 {
  font-size: 18px;
  font-weight: normal;
}

.FormElement-input-rte-toolbar-select-header-five-option,
.DraftEditor-editorContainer h5 {
  font-size: 16px;
  font-weight: normal;
}

.FormElement-input-rte-toolbar-select-header-five-option,
.DraftEditor-editorContainer h6 {
  font-size: 12px;
  font-weight: normal;
}

// Draft

.MegadraftContainer {
  margin: 20px 0 0 30px;

  &.disabled {
    .DraftEditor-editorContainer {
      background-color: #f5f5f5;
      color: #7a7a7a;
      cursor: not-allowed;
    }
  }

  &.new {
    margin: 0px;
    margin-left: 56px;
  }

  .megadraft-block {
    margin-right: 20px;
  }

  .toolbar__list {
    padding-left: 30px !important;
  }

  .characters {
    display: flex;
    justify-content: flex-end;
  }

  .external-link-toolbar-wrapper {
    min-width: 650px;
  }

  .editor__link {
    text-decoration: underline; // restore it
    color: #0579a8;
  }

  a.editor__link {
    color: #051ba8;
  }

  .internal-link-hint {
    background: #0579a8;
    color: white;
    border-radius: 3px;
    font-size: 0.8em;
    margin: 0 0.2em;
    padding: 0.1em;

    &:before {
      padding: 0 0.1em;
      content: '↪';
    }
  }
}

.FormElement {
  /*** draftjs RTE ***/

  .FormElement-input-rte {
    overflow: visible; // changed for Megadraft's toolbar

    .FormElement-input-rte-toolbar {
      margin: 0.5em 0 0 0;

      .FormElement-input-rte-toolbar-select {
        padding: 0.25em 0.5em;
        cursor: pointer;
        margin: 0 0.25em 0.5em 0;
        background: #dde1f2;
        border: 0;
        transition: background-color 0.2s ease-in, color 0.2s ease-in;

        .FormElement-input-rte-toolbar-select-header-two-option {
          font-size: 1.25em;
        }

        .FormElement-input-rte-toolbar-select-header-two-option {
          font-size: 1.6em;
        }

        .FormElement-input-rte-toolbar-select-header-one-option {
          font-size: 1.4em;
        }

        .FormElement-input-rte-toolbar-select-header-three-option {
          font-size: 1.2em;
        }

        .FormElement-input-rte-toolbar-select-header-four-option {
          font-size: 1.15em;
        }

        .FormElement-input-rte-toolbar-select-header-five-option {
          font-size: 1.1em;
        }
      }

      .FormElement-input-rte-toolbar-button {
        padding: 0.25em 0.5em;
        cursor: pointer;
        margin: 0 0.25em 0.5em 0;
        background: #dde1f2;
        position: relative;
        min-width: 0.9em;
        text-align: center;

        transition: background-color 0.2s ease-in, color 0.2s ease-in;

        &:hover {
          background-color: #ced5ef;
        }
      }

      .FormElement-input-rte-toolbar-bold-button {
        b {
          font-weight: 900;
        }
      }

      .FormElement-input-rte-toolbar-italic-button {
        font-family: 'Times New Roman', serif;
        font-weight: 700;
      }

      .FormElement-input-rte-toolbar-unordered-list-button {
        text-indent: -9999em;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url('../../../images/rte/unordered-list-icon.svg') center
            center no-repeat;
          background-size: 1em auto;
        }
      }

      .FormElement-input-rte-toolbar-ordered-list-button {
        text-indent: -9999em;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url('../../../images/rte/ordered-list-icon.svg') center
            center no-repeat;
          background-size: 1em auto;
        }
      }

      .FormElement-input-rte-toolbar-blockquote-button {
        text-indent: -9999em;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url('../../../images/rte/blockquote-icon.svg') center
            center no-repeat;
          background-size: 0.9em auto;
        }
      }

      .FormElement-input-rte-toolbar-underline-button {
        font-weight: 700;
      }

      .FormElement-input-rte-toolbar-link-button {
        text-indent: -9999em;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url('../../../images/rte/link-icon.svg') center center
            no-repeat;
          background-size: 0.9em auto;
        }
      }
    }

    .public-DraftEditor-content {
      min-height: 7em;

      font-family: inherit;
      font-size: 1em;
      border: 0;
      color: black;
      margin: 0 0 0.5em 0;
      padding: 0.25em;
      display: block;
      width: 100%;
      box-sizing: border-box;
      background: transparent;

      border: 1px solid $admin-light-grey;

      transition: border-color 0.4s ease-in;

      .public-DraftStyleDefault-block {
        margin-bottom: 0.5em;

        &:after {
          content: ' ¶';
          color: #bbb;
        }
      }
    }
  }
}
