@media screen {
  .InfoBanner {
    position: fixed;
    right: 0;
    left: 0;
    z-index: $zindex-modal;
    background: $admin-darker;
    color: white;

    transition: transform 0.4s ease-in;

    .InfoBanner-inner {
      padding: 20px 0;
      text-align: center;
      margin: 0 auto;
    }

    .InfoBanner-close {
      display: inline-block;
      padding: 0.25em 0.5em;
      background: white;
      color: $admin-darker;
      cursor: pointer;
      margin-top: 10px;
    }

    .InfoBanner--top {
      top: 0;
    }

    .InfoBanner--top.InfoBanner--closing {
      transform: translate3D(0, -100%, 0);
    }

    .InfoBanner--bottom {
      bottom: 0;
    }

    .InfoBanner--bottom.InfoBanner--closing {
      transform: translate3D(0, 100%, 0);
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
