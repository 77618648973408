@media screen {
  .ObjectPicker-list {
    @include display-flex;
    @include flex-wrap(wrap);
    margin: 0 -10px;

    .ObjectPicker-list-item {
      padding: 0 10px;
      @include flex-basis(calc(100% / 4));
      overflow: hidden;
      @include box-sizing(border-box);
      font-size: 0.9em;

      .ObjectPicker-list-item-button {
        background: $admin-button-lightest;
        margin-bottom: 20px;
        cursor: pointer;
        border-bottom: 2px solid $admin-ui-light;

        &:hover {
          background: $admin-button-light;
        }
      }
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
  .ObjectPicker-list {
    .ObjectPicker-list-item {
      @include flex-basis(calc(100% / 3));
    }
  }
}

@media screen and (max-width: $break1080) {
  .ObjectPicker-list {
    .ObjectPicker-list-item {
      @include flex-basis(calc(100% / 2));
    }
  }
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
  .ObjectPicker-list {
    .ObjectPicker-list-item {
      @include flex-basis(calc(100%));
    }
  }
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
