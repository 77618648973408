@media screen {
  /*** media ratio helpers ***/

  .square-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  .media-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .ToggleMenuButton {
    display: none;
    position: absolute;
    width: 1.8em;
    height: 1.4em;
    padding: 0;
    padding: 10px;
    cursor: pointer;
    text-indent: -9999em;
    z-index: $zindex-above;

    span {
      display: block;
      width: 1.8em;
      height: 2px;
      background: white;
      position: absolute;
      top: calc(50% - 1px);
      left: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 1.8em;
        height: 2px;
        background: white;
        top: -0.5em;
        left: 0;
      }

      &:after {
        content: '';
        position: absolute;
        width: 1.8em;
        height: 2px;
        background: white;
        top: 0.5em;
        left: 0;
      }
    }
  }

  /** layout ***/

  /*** admin main content (rightcol) ***/

  .pre {
    font-family: 'Courier New';
  }

  .warning {
    color: $warning;
  }

  .error {
    color: $alert;
  }

  .success {
    color: $success;
  }

  .small {
    font-size: 0.85em;
  }

  .caps {
    text-transform: uppercase;
  }

  .greyed {
    color: grey;
  }

  .warning-message {
    &::before {
      content: '⚠ ';
    }

    font-weight: bold;
    //font-size: 1.2em;
    background: $warning;
    border-radius: 5px;
    padding: 0.3em;
    width: 100%;
  }

  /*** portals and global overlays ***/
  #dnd-portal {
    position: absolute;
    z-index: $zindex-above-all; /* modal is at 9000 */
  }

  .center-content {
    width: 100%;
    text-align: center;
  }

  @mixin small-badge-in-text($content, $color) {
    content: $content;
    background: rgba($color, 0.25);
    color: $color;
    border-radius: 5px;
    padding: 2px;
    margin-right: 2px;
  }

  .vertical {
    // TODO
    font-size: 0.8em;
  }

  // TODO put in the right place
  .ftp-badge {
    &:before {
      @include small-badge-in-text('FTP', deeppink);
    }
  }

  .upload-badge {
    &:before {
      @include small-badge-in-text('UP', green);
    }
  }

  .stats-dim-selectors {
    @include display-flex;

    // hack
    .FormElement {
      margin: 0;
      padding: 0 0.2em;
      border: none;
    }
  }

  .small-icon {
    height: 1em;
  }

  .comma-separated {
    &:not(:last-child)::after {
      content: ', ';
    }
  }

  .code-diff pre {
    overflow-x: scroll;
  }
}
