@media screen {
  .EntityEditor-fields-list {
    padding-bottom: 2em;
    margin-bottom: 2em;
    border-bottom: 1px solid $admin-grey;

    .EntityEditor-fields-list-item {
      margin: 0.5em 0.5em 0 0;
      padding: 0.25em 0.5em;
      background: $admin-light-grey-border;
      font-weight: 700;
    }
  }

  .EntityEditor-form-element {
    min-width: calc(25% - 1em);
    margin: 0.5em 0;
    width: 100%; // trye to fix buttons wrapping in date picker in modal ex. content grid "1 content"

    .FormElement {
      min-width: 0;
    }

    .EntityEditor-form-element-empty {
      display: inline-block;
      margin-top: 2.65em;
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
  .EntityEditor-form-element {
    min-width: calc(50% - 1em);
  }
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
  .EntityEditor-form-element {
    min-width: calc(100% - 1em);
  }
}

@media screen and (max-width: $break480) {
}
