.site-hierarchy-wrapper {
  h2 {
    //margin-top: 10px;
  }

  .page {
    margin-bottom: 40px;
  }

  .sc {
    font-weight: bold;
  }

  .cc {
    font-weight: bold;
    //color: purple;
    .layout {
      border-left: 2px dotted #f77e7c;
    }
  }

  .layout {
    border-left: 2px dotted #e5e5e5;
    font-weight: normal;
    color: #1f3e00;
    padding-left: 10px;
  }

  .comp {
    font-weight: normal;
    //color: #009490;
  }

  .comp-list {
    padding-left: 10px;
  }

  .item {
    margin-top: 0.5em;
  }

  .retrocompat {
    padding: 1px;
    border: 2px dotted red;
  }

  .comp-description {
    display: flex;
    align-items: center;
  }

  .info {
    border: 1px solid;
    padding: 0 5px;
    display: inline-block;

    &:first-child {
      margin-left: 0.5em;
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
    }

    &:last-child {
      margin-right: 0.5em;
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }
  }

  .type {
    border-color: #887f00;
    background: #f0e200;
  }

  .mode {
    border-color: #00881e;
    background: #36f35b;
    /*
        display: flex;
        flex-direction: column;
        align-items: center;

        &:before {
            content: "mode";
            text-transform: uppercase;
            font-size: 0.7em;
            color: #36f35b;
            background: #00881e;
            width: 100%;
        }*/
  }

  .is-cc {
    border-color: #880400;
    background: #f77e7c;
  }

  .is-sc {
    border-color: #003a88;
    background: #80b7ff;
  }

  .identifier {
    font-family: 'Courier New';
    font-weight: bold;
  }

  .sc-label {
    //color: #003a88;
    font-weight: bold;
  }

  .pop-edit {
    cursor: zoom-in;
  }

  .requester-desc {
    color: #808080;
  }
}
