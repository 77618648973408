.DashboardSquare {
  background: $admin-ui-lighter;
  //margin: 2em;
  /*border-radius: 2.5em;*/
  padding: 1.5em;
  min-height: 15em;
  text-align: center;

  color: inherit;
  text-decoration: none;

  @include display-flex;
  @include flex-direction(column);
  @include flex-justify-content(center);
  @include flex-align-items(center);

  transition: background 0.35s ease-in-out;

  &:hover {
    background: $admin-ui-light;
  }

  .icon {
    width: 3em;
    margin-bottom: 2em;
  }

  h2 {
    margin-bottom: 0.5em;
  }
}
