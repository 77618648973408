.alert-level {
  .alert-level--0 {
    color: rgb(0, 58, 3);
  }

  .alert-level--1 {
    color: #7d7600;
  }

  .alert-level--2 {
    color: #6f4c00;
  }

  .alert-level--3 {
    color: #783c00;
  }

  .alert-level--4,
  .alert-level--5,
  .alert-level--6,
  .alert-level--7 {
    color: #990700;
  }
}
