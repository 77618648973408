.counter.tag {
  padding-left: 0.5em;
  padding-right: 0.5em;
  height: 1.5em;

  .count {
    font-weight: bold;
  }

  .target {
    font-size: 9px;
    margin-bottom: 3px;
    margin-left: 2px;
  }

  .counter-title {
    margin-left: 3px;
  }
}
