@media screen {
  .InputListObject {
    @include display-flex;
    @include flex-wrap(wrap);
    margin: 0 -20px;

    .InputListObject-leftcol,
    .InputListObject-rightcol {
      padding: 0 20px;
      @include flex-basis(50%);
      @include box-sizing(border-box);
      overflow: hidden;

      .ObjectPicker-list-item {
        @include flex-basis(50%);
      }
    }

    .InputListObject-selected-list {
      .InputListObject-selected-list-item {
        @include display-flex;
        @include flex-justify-content(space-between);
        @include flex-align-items(center);

        padding: 0 0.5em;
        margin: 0.5em 0;
        background: $admin-lightest-grey;

        cursor: move;

        &:hover {
          background: $admin-light-grey-border;
        }

        .InputListObject-selected-list-item-object {
          overflow: hidden;
          @include overflowing-text;
        }

        .FormBase-button {
          margin: 0.5em 0;
        }
      }
    }
  }

  /*.InputListFieldDraggable*/
  .InputListField {
    .InputListField-selected-list {
      .InputListField-selected-list-item-draggable {
        // TODO Martin: better

        display: flex;
        background: $admin-lighter-grey;

        &:hover {
          background: $admin-light-grey;
        }
      }
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
  .InputListObject {
    margin: 0 -15px;

    .InputListObject-leftcol,
    .InputListObject-rightcol {
      padding: 0 15px;

      .ObjectPicker-list-item {
        @include flex-basis(100%);
      }
    }
  }
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
  .InputListObject {
    margin: 0 -10px;

    .InputListObject-leftcol,
    .InputListObject-rightcol {
      padding: 0 10px;
      @include flex-basis(100%);

      .ObjectPicker-list-item {
        //@include flex-basis(100%);
      }
    }
  }
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
  .InputListObject-list {
    .InputListObject-list-item {
      @include flex-basis(50%);
    }
  }
}
