.tools-page {
  .check-db {
    ul.check-db-result {
      list-style: circle;
      padding-left: 30px;
      margin-top: 20px;
      margin-left: 10px;
      border-left: 1px solid $grey-lighter;

      > li {
        margin-bottom: 40px;
      }

      ul {
        list-style: square;
        padding-left: 30px;
        margin-top: 10px;
        > li {
          margin-bottom: 20px;
          pre {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
