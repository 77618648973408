// our component main class
$componentMainClass: FormBase;

@media screen {
  /*** buttons ***/
  .#{$componentMainClass}-button {
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    background: $admin-button-lightest;
    padding: 0.15em 0.5em 0.25em 0.5em;
    font-weight: 700;
    margin: 0.5em;
    border-radius: 4px;

    color: black;

    transition: background 0.2s ease-in;

    &:hover {
      background: $admin-button-light;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.active,
    &.active:hover {
      background: $admin-button-active;
    }

    &.important-button {
      background: red;
    }

    &.is-success {
      color: white;
      background: $success;
      &:hover {
        background: rgba($success, $alpha: 0.9);
      }
      &.active,
      &.active:hover {
        background: rgba($success, $alpha: 0.9);
      }
    }
  }

  /*** submit ***/
  .#{$componentMainClass}-submit-button {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    background: $admin-dark;
    padding: 0.5em 1em;
    display: inline-block;
    margin: 0.75em 1em 0 0;
    font-size: 1.15em;
    font-weight: 700;

    transition: background-color 0.3s ease-in;

    &:hover {
      background: $admin-darker;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &--secondary {
      font-size: 0.9em;
      text-transform: none;
      background: transparent;
      color: $admin-darker;
      border: 2px solid $admin-darker;

      &:hover {
        background: $admin-darker;
        color: white;
      }
    }
  }

  .#{$componentMainClass}-icon-button {
    text-indent: -9999em;
    width: 1.4em;
    height: 1.4em;
    display: inline-block;
    margin-left: 0.25em;
    position: relative;
    background: $admin-ui-light;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &-edit {
      &:after {
        background: url('../../images/edit-item-icon.svg') center center
          no-repeat;
        background-size: auto 0.8em;
      }
    }

    &-delete {
      &:after {
        background: url('../../images/delete-icon.svg') center center no-repeat;
        background-size: auto 0.8em;
      }
    }

    &-save {
      &:after {
        background: url('../../images/save-icon.svg') center center no-repeat;
        background-size: auto 0.9em;
      }
    }

    &-cancel {
      &:after {
        background: url('../../images/cancel-icon.svg') center center no-repeat;
        background-size: auto 0.8em;
      }
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
}
