$zindex-footer: 4;

.resource-show,
.resource-new {
  .error {
    font-size: 1.25em;
    border: 3px solid red;
    background: rgba(255, 0, 0, 0.33);
    padding: 5px;

    &:before {
      content: '❌';
    }
  }

  .resource-header {
    margin: calc(-#{$app-content-padding});
    margin-bottom: 20px !important;
    padding: calc(#{$app-content-padding} - 10px);
    background-color: rgba($env-color-rgb, 0.1);
    justify-content: space-between;
    border-bottom: 1px solid $grey-lighter;

    @media screen and (max-width: $sidebarCollapseBreak) {
      margin: -20px;
    }

    .tabs-container {
      margin-bottom: -21px;

      .tabs.is-boxed {
        li a {
          color: $text;
        }
        li.is-active a {
          background-color: $app-content-background-color;
        }
      }
    }
  }

  .resource-edit {
    padding-bottom: 70px;

    .resource-form-footer {
      box-shadow: 0 0px 4px rgba(10, 10, 10, 0.4);
      border: 1px solid $grey-lighter;
      border-bottom: 0px;
      position: fixed;
      background: white;
      right: 0;
      bottom: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      // left: $sidebar-size;
      // width: calc(100vw - #{$sidebar-size});
      left: 400px;
      z-index: $zindex-footer;
      width: calc(100vw - 600px);
      padding: 12px;

      @media screen and (max-width: $break1080) {
        left: $sidebar-size;
        width: calc(100vw - #{$sidebar-size});
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        padding: 12px 20px;
      }

      @media screen and (max-width: $sidebarCollapseBreak) {
        left: $sidebar-retracted-size;
        width: calc(100vw - #{$sidebar-retracted-size});
      }
    }

    .Modal .resource-form-footer {
      position: relative;
      background: transparent;
    }

    div.jsoneditor {
      margin-bottom: 20px;

      &.jsoneditor-mode-code {
        height: 800px;
      }
    }

    .jsoneditor-mode-code {
      height: 800px;
    }
  }
}
