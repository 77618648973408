.FormElement {
  .FormElement-input-textarea {
    font-family: inherit;
    font-size: 1em;
    border: 0;
    color: black;
    margin: 0.5em 0;
    padding: 0.25em;
    min-height: 4.2em;
    display: block;
    width: 100%;
    box-sizing: border-box;
    background: transparent;

    border: 1px solid $admin-light-grey;

    transition: border-color 0.4s ease-in;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border: 1px solid $admin-light-grey-border;
      outline-color: transparent;
    }

    .FormElement-input-textarea--error {
      border: 1px solid red;
    }
  }
}
