.FormBase--secondary {
  .FormElement-input-text {
    margin: 0;
    line-height: 1.2;
    background: rgba(255, 255, 255, 0);

    border: 1px solid $admin-grey;
    outline-color: transparent;

    transition: background 0.2s ease-in, border-color 0.2s ease-in;

    &:focus {
      background: rgba(255, 255, 255, 1);
      border: 1px solid $admin-dark-grey;
    }
  }
}

.FormElement-input-text {
  font-family: inherit;
  font-size: 1em;
  border: 0;
  color: black;
  margin: 0.5em 0;
  padding: 0.25em;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: transparent;

  border-bottom: 1px solid $admin-light-grey;

  transition: border-color 0.4s ease-in;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-bottom: 1px solid $admin-light-grey-border;
    outline-color: transparent;
  }

  .FormElement-input-text--error {
    border-bottom: 1px solid $alert;
  }

  &.date {
    display: inline-block;
    width: auto;
  }
}
