// our component main class
$componentMainClass: Renderer;

@media screen {
  .#{$componentMainClass}-Default {
    &-thumb {
      img {
        display: block;
        max-width: 200px;
        max-height: 200px;
        object-fit: scale-down;
        width: 100%; // new for layout to prevent overflow
      }
    }

    &-label {
      padding: 0.25em 0.5em;
      @include box-sizing(border-box);
      font-weight: 700;
      @include overflowing-text;
      overflow: hidden;
    }
  }

  .#{$componentMainClass}-Default.TextContent {
    display: block;
    max-width: 250px;
    margin: 0 0.5em 0.5em 0;

    background: $admin-light;
    color: white;
  }

  .#{$componentMainClass}-Default.ContentRequester {
    display: block;
    max-width: 250px;
    margin: 0.5em 0.5em 0.5em 0;
    padding: 0 0.5em;
    box-sizing: border-box;

    background: $admin-light;
    color: white;
  }

  .#{$componentMainClass}-Default.ObjectPropertyRequester {
    display: block;
    max-width: 250px;
    margin: 0.5em 0.5em 0.5em 0;
    padding: 0 0.5em;
    box-sizing: border-box;

    background: $admin-light;
    color: white;
  }

  .VideoRenderer-Default {
    border-radius: 2px;
    padding: 1px 4px;

    &.encodingStatus-done {
      /*background: #aad185;

            &::before {
                content: "✔️ ";
            }*/
    }

    &.encodingStatus-pending {
      background: #90e1f3;

      &::before {
        content: '⏳ ';
      }
    }

    &.encodingStatus-encoding {
      background: #f7c247;

      &::before {
        content: '⚙️ ';
      }
    }

    &.encodingStatus-error {
      background: #f98686;

      &::before {
        content: '❌ ';
      }
    }
  }

  .#{$componentMainClass}-Default.SubscriptionFormula {
    &.obsolete {
      background: rgb(226, 148, 143);

      .label {
        text-decoration: line-through;
      }
    }
  }

  .purchase-subinfo-details {
    .renewed {
      color: blue;

      &::before {
        content: '✔';
        background: blue;
        color: $admin-lighter-grey;
        border-radius: 0.25em;
        padding: 0.1em;
        margin-right: 0.1em;
      }
    }

    .renewal {
      color: #12a100;

      &::before {
        content: '↻';
        background: #12a100;
        color: $admin-lighter-grey;
        border-radius: 0.25em;
        padding: 0.1em;
        margin-right: 0.1em;
      }
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
}

@media screen and (max-width: $break1080) {
  .#{$componentMainClass}-Default {
    &-thumb {
      img {
        display: block;
        max-width: 150px;
        max-height: 150px;
        object-fit: scale-down;
      }
    }
  }
}

@media screen and (max-width: $break840) {
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
  .#{$componentMainClass}-form-element {
    min-width: calc(100% - 1em);
  }
}

@media screen and (max-width: $break480) {
}
