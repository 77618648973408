// Bulma override
$env-color: var(--environment-primary-color, blue);

// Sidebar
$env-color: var(--environment-primary-color, blue);
$env-color-rgb: var(--environment-primary-color-rgb, blue);
$menu-item-active-background-color: $env-color;
$menu-item-hover-background-color: #f3f3f3;

// Navbar
$navbar-background-color: white;
$navbar-item-hover-background-color: #f3f3f3;
$navbar-item-active-background-color: $env-color;
$navbar-item-hover-color: #4a4a4a;
$navbar-item-active-color: white;
$navbar-box-shadow-color: rgba(0, 0, 0, 0.2);
$navbar-box-shadow-size: 0 2px 4px 0;
$navbar-item-color: #4a4a4a;
$navbar-dropdown-arrow: #4a4a4a;
$navbar-dropdown-item-active-color: white;

// Table
$table-row-hover-background-color: #f1f1f1;
$table-striped-row-even-hover-background-color: #f1f1f1;

$block-spacing: 1rem;

// Pagination

$pagination-current-background-color: $env-color;
$input-focus-border-color: #3e8f48;
$input-focus-box-shadow-color: rgba(0, 143, 0, 0.19);
