@media screen {
  .DiffusionList {
    .DiffusionList-selected-list-item {
      background: $admin-lightest-grey;
      position: relative;
      padding: 0.5em;
      margin: 0.5em 0;

      .DiffusionList-selected-list-item-delete {
        position: absolute;
        top: 0;
        right: 0;
      }

      .FormMultiElements {
        .FormElement {
          margin: 1em 0;

          &:first-child {
            margin-top: 0;
          }

          .FormElement {
            margin: 0 1em 0 0 !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $break1480) {
}

@media screen and (max-width: $break1240) {
  .DiffusionList {
  }
}

@media screen and (max-width: $break1080) {
}

@media screen and (max-width: $break840) {
  .DiffusionList {
  }
}

@media screen and (max-width: $break720) {
}

@media screen and (max-width: $break640) {
}

@media screen and (max-width: $break480) {
  .DiffusionList-list {
    .DiffusionList-list-item {
      @include flex-basis(50%);
    }
  }
}
