.FormBase {
  .FormMultiElements {
    .FormMultiElements-fields {
      @include display-flex;
      @include flex-wrap(wrap);
      @include flex-align-content(flex-start);
      @include flex-align-items(flex-start);

      .FormElement {
        padding-left: 0;
        border: 0;
        margin: 0.5em 0 !important;

        min-width: calc(25% - 1em);

        .FormElement-input-text-element,
        .FormElement-input-select-element,
        .FormElement-input-checkbox-element {
          margin-right: 1em !important;

          .FormElement-input-checkbox-element-input-checkbox-element {
            margin-top: 2.4em !important; // Whyyyy why why why, tell em why. Where is it needed??;
          }
          .FormMultiElements-fields {
            margin: 0.5em 0;
          }
        }
      }
      .FormMultiElements-fields--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

@media screen and (max-width: $break1080) {
  .FormMultiElements {
    /*** multi fields ***/
    &-fields {
      .FormElement {
        min-width: calc(50% - 1em);
      }
    }
  }
}

@media screen and (max-width: $break720) {
  .FormMultiElements {
    /*** multi fields ***/
    &-fields {
      .FormElement {
        min-width: calc(100% - 1em);
      }
    }
  }
}
